"use client";
import React from "react";
import AnimatedSun from "./Icons/AnimatedSun";
import AnimatedMoon from "./Icons/AnimatedMoon";
import AnimatedCloud from "./Icons/AnimatedCloud";
import AnimatedRain from "./Icons/AnimatedRain";
import AnimatedHeavyRain from "./Icons/AnimatedHeavyRain";
import AnimatedExtremeRain from "./Icons/AnimatedExtremeRain";
import AnimatedSnow from "./Icons/AnimatedSnow";
import AnimatedHeavySnow from "./Icons/AnimatedHeavySnow";
import AnimatedExtremeSnow from "./Icons/AnimatedExtremeSnow";
import AnimatedSleet from "./Icons/AnimatedSleet";
import AnimatedThunder from "./Icons/AnimatedThunder";
import AnimatedThunderRain from "./Icons/AnimatedThunderRain";

const ICON_TYPES = {
  CLEAR_DAY: "clear-day",
  CLEAR_NIGHT: "clear-night",
  CLOUDY: "cloudy",
  RAIN: "rain",
  HEAVY_RAIN: "heavy-rain",
  EXTREME_RAIN: "extreme-rain",
  SNOW: "snow",
  HEAVY_SNOW: "heavy-snow",
  EXTREME_SNOW: "extreme-snow",
  SLEET: "sleet",
  THUNDER: "thunder",
  THUNDER_RAIN: "thunder-rain",
};

type SizeVariant = "sm" | "md" | "lg" | "xl";

interface WeatherIconProps {
  weatherType?: string;
  isDaytime?: boolean;
  size?: SizeVariant | number;
  className?: string;
}

export default function WeatherIcon({
  weatherType = "clear day",
  isDaytime = true,
  size,
  className,
}: WeatherIconProps) {
  // 1) Figure out the numeric size
  let finalSize = 64;
  if (typeof size === "number") {
    finalSize = size;
  } else if (size) {
    switch (size) {
      case "sm":
        finalSize = 32;
        break;
      case "md":
        finalSize = 48;
        break;
      case "lg":
        finalSize = 64;
        break;
      case "xl":
        finalSize = 96;
        break;
    }
  }

  // 2) Map weatherType to a known icon constant
  const mapWeatherToIcon = (type: string) => {
    const lower = type.trim().toLowerCase();
    // Handle "thunder-rain" specifically (including with hyphens)
    if (lower === "thunder-rain" || lower === "thunder rain" || lower.includes("thunder") && lower.includes("rain")) {
      return ICON_TYPES.THUNDER_RAIN;
    }
    // Handle just "thunder" as a string
    if (lower === "thunder" || lower.includes("thunder")) {
      return ICON_TYPES.THUNDER;
    }
    if (lower.includes("extreme-rain")) return ICON_TYPES.EXTREME_RAIN;
    if (lower.includes("heavy-rain")) return ICON_TYPES.HEAVY_RAIN;
    if (lower.includes("rain")) return ICON_TYPES.RAIN;
    if (lower.includes("extreme-snow")) return ICON_TYPES.EXTREME_SNOW;
    if (lower.includes("heavy-snow")) return ICON_TYPES.HEAVY_SNOW;
    if (lower.includes("snow")) return ICON_TYPES.SNOW;
    if (lower.includes("sleet")) return ICON_TYPES.SLEET;
    if (lower.includes("cloud")) return ICON_TYPES.CLOUDY;
    // fallback
    return isDaytime ? ICON_TYPES.CLEAR_DAY : ICON_TYPES.CLEAR_NIGHT;
  };

  const iconType = mapWeatherToIcon(weatherType);

  // 3) Render the correct component
  const renderIcon = () => {
    switch (iconType) {
      case ICON_TYPES.CLEAR_DAY:
        return <AnimatedSun size={finalSize} />;
      case ICON_TYPES.CLEAR_NIGHT:
        return <AnimatedMoon size={finalSize} />;
      case ICON_TYPES.CLOUDY:
        return <AnimatedCloud size={finalSize} />;
      case ICON_TYPES.RAIN:
        return <AnimatedRain size={finalSize} />;
      case ICON_TYPES.HEAVY_RAIN:
        return <AnimatedHeavyRain size={finalSize} />;
      case ICON_TYPES.EXTREME_RAIN:
        return <AnimatedExtremeRain size={finalSize} />;
      case ICON_TYPES.SNOW:
        return <AnimatedSnow size={finalSize} />;
      case ICON_TYPES.HEAVY_SNOW:
        return <AnimatedHeavySnow size={finalSize} />;
      case ICON_TYPES.EXTREME_SNOW:
        return <AnimatedExtremeSnow size={finalSize} />;
      case ICON_TYPES.SLEET:
        return <AnimatedSleet size={finalSize} />;
      case ICON_TYPES.THUNDER:
        return <AnimatedThunder size={finalSize} />;
      case ICON_TYPES.THUNDER_RAIN:
        return <AnimatedThunderRain size={finalSize} />;
      default:
        return (
          <div
            style={{
              width: finalSize,
              height: finalSize,
              backgroundColor: "transparent",
            }}
          />
        );
    }
  };

  return <div className={className}>{renderIcon()}</div>;
}