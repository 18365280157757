"use client";

import React, { useRef, useEffect } from "react";

interface AnimatedSnowProps {
  /** Color for the cloud's outer shape */
  cloudColor?: string;
  /** Color for the cloud's internal shape */
  cloudInnerColor?: string;
  /** Color for the snowflakes */
  flakeColor?: string;
  /** Canvas size in pixels (width & height). */
  size?: number;
  /** How many snowflakes to draw at once. */
  flakeCount?: number;
  /** Multiplier for speed. 1 = normal, 2 = faster, etc. */
  speed?: number;
  /** Additional CSS classes. */
  className?: string;
}

function AnimatedSnow({
  cloudColor = "#434343", // default outer cloud color
  cloudInnerColor = "#7c7777", // default inner cloud color
  flakeColor = "#c934df", // default snowflake color
  size = 64,
  flakeCount = 4,
  speed = 1,
  className = "",
}: AnimatedSnowProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const requestRef = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  // 2π constant
  const TAU = 2 * Math.PI;
  const STROKE = 0.08;

  function circle(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    r: number
  ) {
    ctx.beginPath();
    ctx.arc(x, y, r, 0, TAU, false);
    ctx.fill();
  }

  function puffs(
    ctx: CanvasRenderingContext2D,
    t: number,
    cx: number,
    cy: number,
    rx: number,
    ry: number,
    rmin: number,
    rmax: number
  ) {
    function puff(
      ctx: CanvasRenderingContext2D,
      t: number,
      cx: number,
      cy: number,
      rx: number,
      ry: number,
      rmin: number,
      rmax: number
    ) {
      const c = Math.cos(t * TAU);
      const s = Math.sin(t * TAU);
      const rm = rmax - rmin;
      circle(ctx, cx - s * rx, cy + c * ry + rm * 0.5, rmin + (1 - c * 0.5) * rm);
    }
    for (let i = 0; i < 5; i++) {
      puff(ctx, t + i / 5, cx, cy, rx, ry, rmin, rmax);
    }
  }

  function cloud(
    ctx: CanvasRenderingContext2D,
    t: number,
    cx: number,
    cy: number,
    cw: number,
    s: number,
    outerColor: string,
    innerColor: string
  ) {
    // slow drift
    t /= 30000;

    const a = cw * 0.21;
    const b = cw * 0.12;
    const c = cw * 0.24;
    const d = cw * 0.28;

    // --- 1) Draw the outer cloud shape ---
    ctx.fillStyle = outerColor;
    puffs(ctx, t, cx, cy, a, b, c, d);

    // --- 2) Carve out a smaller region in the center ---
    ctx.save();
    ctx.globalCompositeOperation = "destination-out";
    puffs(ctx, t, cx, cy, a, b, c - s, d - s);
    ctx.restore();

    // --- 3) Fill that smaller region with the inner color ---
    ctx.fillStyle = innerColor;
    puffs(ctx, t, cx, cy, a, b, c - s, d - s);
  }

  function line(
    ctx: CanvasRenderingContext2D,
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ) {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
  }

  function drawSnow(
    ctx: CanvasRenderingContext2D,
    elapsed: number,
    cx: number,
    cy: number,
    cw: number,
    s: number,
    color: string
  ) {
    // base time ~3000ms, scaled by speed
    const t = (elapsed / 3000) * speed;

    const a = cw * 0.16;
    const armLen = s * 0.75;

    const u = t * TAU * 0.7; // rotation angle
    const ux = Math.cos(u) * armLen;
    const uy = Math.sin(u) * armLen;
    const v = u + TAU / 3;
    const vx = Math.cos(v) * armLen;
    const vy = Math.sin(v) * armLen;
    const w = u + (2 * TAU) / 3;
    const wx = Math.cos(w) * armLen;
    const wy = Math.sin(w) * armLen;

    ctx.strokeStyle = color;
    ctx.lineWidth = s * 0.5;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    for (let i = 0; i < flakeCount; i++) {
      const p = (t + i / flakeCount) % 1;
      const x = cx + Math.sin((p + i / flakeCount) * TAU) * a;
      const y = cy + p * cw;

      line(ctx, x - ux, y - uy, x + ux, y + uy);
      line(ctx, x - vx, y - vy, x + vx, y + vy);
      line(ctx, x - wx, y - wy, x + wx, y + wy);
    }
  }

  function drawFrame(ctx: CanvasRenderingContext2D, elapsed: number) {
    const w = ctx.canvas.width;
    const h = ctx.canvas.height;
    const s = Math.min(w, h);

    ctx.clearRect(0, 0, w, h);

    // 1) Snow
    drawSnow(ctx, elapsed, w * 0.5, h * 0.37, s * 0.9, s * STROKE, flakeColor);
    // 2) Two-tone cloud
    cloud(ctx, elapsed, w * 0.5, h * 0.37, s * 0.9, s * STROKE, cloudColor, cloudInnerColor);
  }

  const animate = (timestamp: number) => {
    if (startTimeRef.current === null) {
      startTimeRef.current = timestamp;
    }
    const elapsed = timestamp - startTimeRef.current;

    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        drawFrame(ctx, elapsed);
      }
    }
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [cloudColor, cloudInnerColor, flakeColor, size, flakeCount, speed]);

  return (
    <canvas
      ref={canvasRef}
      className={className}
      width={size}
      height={size}
      style={{ width: size, height: size }}
    />
  );
}

export default AnimatedSnow;