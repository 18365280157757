"use client";

import React, { useEffect, useRef } from "react";

const TAU = 2.0 * Math.PI;
const STROKE_WIDTH_RATIO = 0.08;
const RAY_COUNT = 8;
const ANIMATION_SPEED = 120000;

// Helper: Draw a circle with stroke only
function drawCircle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number
) {
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, TAU, false);
  ctx.stroke();
}

// Helper: Draw a line
function drawLine(
  ctx: CanvasRenderingContext2D,
  startX: number,
  startY: number,
  endX: number,
  endY: number
) {
  ctx.beginPath();
  ctx.moveTo(startX, startY);
  ctx.lineTo(endX, endY);
  ctx.stroke();
}

interface DrawingDimensions {
  size: number;
  centerX: number;
  centerY: number;
  strokeWidth: number;
  innerRadius: number;
  rayStartRadius: number;
  rayEndRadius: number;
}

// Compute sizes and positions based on canvas dimensions
function getDrawingDimensions(
  canvasWidth: number,
  canvasHeight: number
): DrawingDimensions {
  const size = Math.min(canvasWidth, canvasHeight);
  const centerX = canvasWidth * 0.5;
  const centerY = canvasHeight * 0.5;
  const strokeWidth = size * STROKE_WIDTH_RATIO;

  return {
    size,
    centerX,
    centerY,
    strokeWidth,
    innerRadius: size * 0.25 - strokeWidth * 0.5,
    rayStartRadius: size * 0.32 + strokeWidth * 0.5,
    rayEndRadius: size * 0.5 - strokeWidth * 0.5,
  };
}

// Draw the sun core (now filled + stroked)
function drawSunCore(
  ctx: CanvasRenderingContext2D,
  dimensions: DrawingDimensions
) {
  const { centerX, centerY, strokeWidth, innerRadius } = dimensions;

  // Fill + Stroke colors for the core
  ctx.fillStyle = "#fad43f";
  ctx.strokeStyle = "#ca8b6a";
  ctx.lineWidth = strokeWidth;
  ctx.lineCap = "round";
  ctx.lineJoin = "round";

  // Draw filled circle
  ctx.beginPath();
  ctx.arc(centerX, centerY, innerRadius, 0, TAU, false);
  ctx.fill();

  // Then stroke the same circle (optional if you want an outline)
  ctx.stroke();
}

// Draw sun rays
function drawSunRays(
  ctx: CanvasRenderingContext2D,
  dimensions: DrawingDimensions,
  time: number
) {
  const { centerX, centerY, rayStartRadius, rayEndRadius } = dimensions;

  // Hard-coded stroke color for rays
  ctx.strokeStyle = "#ca8b6a";

  for (let i = 0; i < RAY_COUNT; i++) {
    const angle = (time + i / RAY_COUNT) * TAU;
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);

    const startX = centerX + cos * rayStartRadius;
    const startY = centerY + sin * rayStartRadius;
    const endX = centerX + cos * rayEndRadius;
    const endY = centerY + sin * rayEndRadius;

    drawLine(ctx, startX, startY, endX, endY);
  }
}

// Clear canvas & draw sun
function drawSun(ctx: CanvasRenderingContext2D, time: number) {
  const { width, height } = ctx.canvas;
  ctx.clearRect(0, 0, width, height);

  const dimensions = getDrawingDimensions(width, height);
  drawSunCore(ctx, dimensions);
  drawSunRays(ctx, dimensions, time / ANIMATION_SPEED);
}

type AnimationCallback = (elapsed: number) => void;

// Custom hook for animating with requestAnimationFrame
function useAnimation(callback: AnimationCallback) {
  const animationRef = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  useEffect(() => {
    startTimeRef.current = Date.now();

    const animate = () => {
      const startTime = startTimeRef.current ?? Date.now();
      const elapsed = Date.now() - startTime;
      callback(elapsed);
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [callback]);
}

interface AnimatedSunProps {
  size?: number; // optional: set canvas size
}

// Main AnimatedSun component
const AnimatedSun: React.FC<AnimatedSunProps> = ({ size = 64 }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const animate = (elapsed: number) => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx) {
      drawSun(ctx, elapsed);
    }
  };

  useAnimation(animate);

  return (
    <canvas
      ref={canvasRef}
      width={size}
      height={size}
      className="w-full h-full"
    />
  );
};

export default AnimatedSun;
