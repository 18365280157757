// File: AnimatedSleet.tsx
"use client";

import React, { useRef, useEffect } from "react";

/**
 * Props for the AnimatedSleet component
 */
interface AnimatedSleetProps {
  color?: string;
  size?: number;
  className?: string;
}

const AnimatedSleet: React.FC<AnimatedSleetProps> = ({
  color = "#000000",
  size = 64,
  className = "",
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const requestRef = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  // 2π constant
  const TAU = 2 * Math.PI;
  // “Stroke” size factor used in drawing
  const STROKE = 0.08;

  /**
   * A helper to draw a filled circle.
   */
  function circle(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    r: number
  ) {
    ctx.beginPath();
    ctx.arc(x, y, r, 0, TAU, false);
    ctx.fill();
  }

  /**
   * A helper that draws repeated “puffs” to form the puffy cloud shape.
   */
  function puffs(
    ctx: CanvasRenderingContext2D,
    t: number,
    cx: number,
    cy: number,
    rx: number,
    ry: number,
    rmin: number,
    rmax: number
  ) {
    function puff(
      ctx: CanvasRenderingContext2D,
      t: number,
      cx: number,
      cy: number,
      rx: number,
      ry: number,
      rmin: number,
      rmax: number
    ) {
      const c = Math.cos(t * TAU);
      const s = Math.sin(t * TAU);
      const rm = rmax - rmin;
      circle(ctx, cx - s * rx, cy + c * ry + rm * 0.5, rmin + (1 - c * 0.5) * rm);
    }

    // 5 puffs in a loop
    for (let i = 0; i < 5; i++) {
      puff(ctx, t + i / 5, cx, cy, rx, ry, rmin, rmax);
    }
  }

  /**
   * Draw a puffy cloud at (cx, cy).
   */
  function cloud(
    ctx: CanvasRenderingContext2D,
    t: number,
    cx: number,
    cy: number,
    cw: number,
    s: number,
    color: string
  ) {
    // slow drift factor
    t /= 30000;

    const a = cw * 0.21;
    const b = cw * 0.12;
    const c = cw * 0.24;
    const d = cw * 0.28;

    ctx.fillStyle = color;
    // big puffs
    puffs(ctx, t, cx, cy, a, b, c, d);

    // punch out edges
    ctx.globalCompositeOperation = "destination-out";
    puffs(ctx, t, cx, cy, a, b, c - s, d - s);

    // restore
    ctx.globalCompositeOperation = "source-over";
  }

  /**
   * A helper to draw a line from (x1,y1) to (x2,y2).
   */
  function line(
    ctx: CanvasRenderingContext2D,
    x1: number,
    y1: number,
    x2: number,
    y2: number
  ) {
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.stroke();
  }

  /**
   * Draw sleet: a mix of small raindrops + a few snowflake lines.
   */
  function drawSleet(
    ctx: CanvasRenderingContext2D,
    elapsed: number,
    cx: number,
    cy: number,
    cw: number,
    s: number,
    color: string
  ) {
    // We'll base the timing on 750 ms for one cycle
    const t = (elapsed / 750) % 1;
    // horizontal range for the precipitation
    const a = cw * 0.1875;

    ctx.lineWidth = s * 0.5;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.strokeStyle = color;
    ctx.fillStyle = color;

    // 1) Raindrops (like drizzle)
    for (let i = 0; i < 2; i++) {
      const p = (t + i / 4) % 1;
      const x = Math.floor(cx + ((i - 1.5) / 1.5) * a) + 0.5;
      const y = cy + p * cw;
      // short vertical line for a small raindrop
      line(ctx, x, y - s * 1.5, x, y + s * 1.5);
    }

    // 2) Snowflake lines
    const b = s * 0.75;
    const u = elapsed / 1000; // continuous angle
    const ux = Math.cos(u) * b;
    const uy = Math.sin(u) * b;
    const v = u + TAU / 3;
    const vx = Math.cos(v) * b;
    const vy = Math.sin(v) * b;
    const w = u + (TAU * 2) / 3;
    const wx = Math.cos(w) * b;
    const wy = Math.sin(w) * b;

    for (let i = 2; i < 4; i++) {
      const p2 = (t + i / 4) % 1;
      const x2 = cx + ((i - 2.5) / 1.5) * a;
      const y2 = cy + p2 * cw;
      // 3 crossing lines forming a snowflake
      line(ctx, x2 - ux, y2 - uy, x2 + ux, y2 + uy);
      line(ctx, x2 - vx, y2 - vy, x2 + vx, y2 + vy);
      line(ctx, x2 - wx, y2 - wy, x2 + wx, y2 + wy);
    }
  }

  /**
   * Draw loop: 1) Clear, 2) Sleet, 3) Cloud.
   */
  function drawFrame(ctx: CanvasRenderingContext2D, elapsed: number) {
    const w = ctx.canvas.width;
    const h = ctx.canvas.height;
    const s = Math.min(w, h);

    // Clear
    ctx.clearRect(0, 0, w, h);

    // Sleet below
    drawSleet(ctx, elapsed, w * 0.5, h * 0.37, s * 0.9, s * STROKE, color);

    // Cloud on top
    cloud(ctx, elapsed, w * 0.5, h * 0.37, s * 0.9, s * STROKE, color);
  }

  /**
   * requestAnimationFrame loop
   */
  const animate = (timestamp: number) => {
    if (startTimeRef.current === null) {
      startTimeRef.current = timestamp;
    }
    const elapsed = timestamp - startTimeRef.current;

    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        drawFrame(ctx, elapsed);
      }
    }
    requestRef.current = requestAnimationFrame(animate);
  };

  // Start animation on mount; cleanup on unmount
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [color, size]);

  return (
    <canvas
      ref={canvasRef}
      className={className}
      width={size}
      height={size}
      style={{ width: size, height: size }}
    />
  );
};

export default AnimatedSleet;
