"use client";

import React, { useEffect, useRef } from "react";

// Constants
const TAU = 2.0 * Math.PI;
const STROKE_WIDTH_RATIO = 0.08;
const ANIMATION_SPEED = 30000;
const PUFF_COUNT = 5;

// Minimal interface for our return shape
interface DrawingDimensions {
  size: number;
  centerX: number;
  centerY: number;
  puffRadiusX: number;
  puffRadiusY: number;
  puffMinRadius: number;
  puffMaxRadius: number;
}

// Helper functions for drawing
function drawCircle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number
) {
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, TAU, false);
  ctx.fill();
}

// Individual puff drawing function
function drawPuff(
  ctx: CanvasRenderingContext2D,
  t: number,
  cx: number,
  cy: number,
  rx: number,
  ry: number,
  rmin: number,
  rmax: number
) {
  const c = Math.cos(t * TAU);
  const s = Math.sin(t * TAU);
  const radiusOffset = rmax - rmin;

  drawCircle(
    ctx,
    cx - s * rx,
    cy + c * ry + radiusOffset * 0.5,
    rmin + (1 - c * 0.5) * radiusOffset
  );
}

// Multiple puffs drawing function
function drawPuffs(
  ctx: CanvasRenderingContext2D,
  t: number,
  cx: number,
  cy: number,
  rx: number,
  ry: number,
  rmin: number,
  rmax: number
) {
  for (let i = 0; i < PUFF_COUNT; i++) {
    drawPuff(ctx, t + i / PUFF_COUNT, cx, cy, rx, ry, rmin, rmax);
  }
}

// Drawing configurations
function getDrawingDimensions(
  canvasWidth: number,
  canvasHeight: number
): DrawingDimensions {
  const size = Math.min(canvasWidth, canvasHeight);
  const centerX = canvasWidth * 0.5;
  const centerY = canvasHeight * 0.5;

  return {
    size,
    centerX,
    centerY,
    puffRadiusX: size * 0.21,
    puffRadiusY: size * 0.12,
    puffMinRadius: size * 0.24,
    puffMaxRadius: size * 0.28,
  };
}

// Updated drawing function with two-tone cloud (like AnimatedRain)
function drawCloud(
  ctx: CanvasRenderingContext2D,
  time: number,
  outerColor: string,
  innerColor: string
) {
  const { width, height } = ctx.canvas;

  // Clear canvas
  ctx.clearRect(0, 0, width, height);

  // Get dimensions
  const dimensions = getDrawingDimensions(width, height);
  const {
    centerX,
    centerY,
    puffRadiusX,
    puffRadiusY,
    puffMinRadius,
    puffMaxRadius,
    size
  } = dimensions;

  const t = time / ANIMATION_SPEED;
  const strokeSize = STROKE_WIDTH_RATIO * size;

  // 1. Draw the outer cloud shape
  ctx.fillStyle = outerColor;
  drawPuffs(ctx, t, centerX, centerY, puffRadiusX, puffRadiusY, puffMinRadius, puffMaxRadius);

  // 2. Carve out a smaller region in the center
  ctx.save();
  ctx.globalCompositeOperation = "destination-out";
  drawPuffs(
    ctx,
    t,
    centerX,
    centerY,
    puffRadiusX,
    puffRadiusY,
    puffMinRadius - strokeSize,
    puffMaxRadius - strokeSize
  );
  ctx.restore();

  // 3. Fill that smaller region with the inner color
  ctx.fillStyle = innerColor;
  drawPuffs(
    ctx,
    t,
    centerX,
    centerY,
    puffRadiusX,
    puffRadiusY,
    puffMinRadius - strokeSize,
    puffMaxRadius - strokeSize
  );
}

// Animation hook
function useAnimation(callback: (elapsed: number) => void) {
  const animationRef = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  useEffect(() => {
    startTimeRef.current = Date.now();

    const animate = () => {
      // Safely handle possible null
      const startTime = startTimeRef.current ?? Date.now();
      const elapsed = Date.now() - startTime;
      callback(elapsed);
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [callback]);
}

// Updated props for the AnimatedCloud component
interface AnimatedCloudProps {
  // Updated to match AnimatedRain's cloud colors
  cloudColor?: string;
  cloudInnerColor?: string;
  size?: number;
  className?: string;
}

// Updated main component
const AnimatedCloud: React.FC<AnimatedCloudProps> = ({
  cloudColor = "#666666",
  cloudInnerColor = "#f6f5f5",
  size = 64,
  className = ""
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const animate = (elapsed: number) => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx) {
      drawCloud(ctx, elapsed, cloudColor, cloudInnerColor);
    }
  };

  useAnimation(animate);

  return (
    <canvas
      ref={canvasRef}
      width={size}
      height={size}
      className={className || "w-full h-full"}
      style={{ width: size, height: size }}
    />
  );
};

export default AnimatedCloud;