"use client";

import React, { useEffect, useRef } from "react";

// Constants
const TAU = 2.0 * Math.PI;
const STROKE_WIDTH_RATIO = 0.08;
const ANIMATION_SPEED = 15000;
const TWO_OVER_SQRT_2 = 2.0 / Math.sqrt(2);

// A helper function for drawing arcs
function drawArc(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
  counterClockwise: boolean
) {
  ctx.beginPath();
  ctx.arc(x, y, radius, startAngle, endAngle, counterClockwise);
}

// Define a shape for our drawing dimensions
interface DrawingDimensions {
  size: number;
  centerX: number;
  centerY: number;
  strokeWidth: number;
  moonRadius: number;
  oscillationOffset: number;
}

// Compute sizes/positions
function getDrawingDimensions(canvasWidth: number, canvasHeight: number): DrawingDimensions {
  const size = Math.min(canvasWidth, canvasHeight);
  const centerX = canvasWidth * 0.5;
  const centerY = canvasHeight * 0.5;
  const strokeWidth = size * STROKE_WIDTH_RATIO;

  return {
    size,
    centerX,
    centerY,
    strokeWidth,
    moonRadius: size * 0.29 - strokeWidth * 0.5,
    oscillationOffset: size * 0.05,
  };
}

// Main drawing function
function drawMoon(ctx: CanvasRenderingContext2D, time: number, color: string) {
  const { width, height } = ctx.canvas;

  // Clear canvas
  ctx.clearRect(0, 0, width, height);

  // Get dimensions
  const dimensions = getDrawingDimensions(width, height);
  const { centerX, centerY, strokeWidth, moonRadius, oscillationOffset } = dimensions;

  // Calculate oscillation for a slight horizontal shift
  const t = time / ANIMATION_SPEED;
  const oscillation = Math.cos(t * TAU);
  const phaseAngle = (oscillation * TAU) / -16;

  // Shift center slightly
  const adjustedCenterX = centerX + oscillation * oscillationOffset;

  // Prepare drawing styles
  ctx.strokeStyle = color;
  ctx.lineWidth = strokeWidth;
  ctx.lineCap = "round";
  ctx.lineJoin = "round";

  // Build the "phased" moon shape
  ctx.beginPath();
  // First arc
  ctx.arc(
    adjustedCenterX,
    centerY,
    moonRadius,
    phaseAngle + TAU / 8,
    phaseAngle + (TAU * 7) / 8,
    false
  );

  // Second arc
  ctx.arc(
    adjustedCenterX + Math.cos(phaseAngle) * moonRadius * TWO_OVER_SQRT_2,
    centerY + Math.sin(phaseAngle) * moonRadius * TWO_OVER_SQRT_2,
    moonRadius,
    phaseAngle + (TAU * 5) / 8,
    phaseAngle + (TAU * 3) / 8,
    true
  );
  ctx.closePath();

  // **FILL** with the same color
  ctx.fillStyle = color;
  ctx.fill();

  // Optionally, also stroke to see an outline
  ctx.stroke();
}

// Animation hook
function useAnimation(callback: (elapsed: number) => void) {
  const animationRef = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  useEffect(() => {
    startTimeRef.current = Date.now();

    const animate = () => {
      const startTime = startTimeRef.current ?? Date.now();
      const elapsed = Date.now() - startTime;
      callback(elapsed);
      animationRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      if (animationRef.current !== null) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [callback]);
}

// Component props
interface AnimatedMoonProps {
  color?: string;
  size?: number;
}

// Main component
const AnimatedMoon: React.FC<AnimatedMoonProps> = ({
  // The default color for both fill & stroke
  color = "#aecde3",
  size = 64,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const animate = (elapsed: number) => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx) {
      drawMoon(ctx, elapsed, color);
    }
  };

  useAnimation(animate);

  return (
    <canvas
      ref={canvasRef}
      width={size}
      height={size}
      className="w-full h-full"
    />
  );
};

export default AnimatedMoon;
