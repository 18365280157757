// File: AnimatedRain.tsx
"use client";

import React, { useRef, useEffect } from "react";

interface AnimatedRainProps {
  /** Color for the cloud's outer shape */
  cloudColor?: string;
  /** Color for the cloud's internal shape */
  cloudInnerColor?: string;
  /** Color for the raindrops */
  rainColor?: string;
  /** Canvas size in pixels (width & height). */
  size?: number;
  /** Additional CSS classes. */
  className?: string;
  /** How many raindrops to draw at once. */
  dropCount?: number;
  /** Speed multiplier for the falling drops. */
  speed?: number;
}

const AnimatedRain: React.FC<AnimatedRainProps> = ({
  cloudColor = "#666666",
  cloudInnerColor = "#c6c2c2",
  rainColor = "#564a9f",
  size = 64,
  className = "",
  dropCount = 3,
  speed = 1.5,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const requestRef = useRef<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  const TAU = 2 * Math.PI;
  const STROKE = 0.08;

  /**
   * Draws a filled circle.
   */
  function circle(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    r: number
  ) {
    ctx.beginPath();
    ctx.arc(x, y, r, 0, TAU, false);
    ctx.fill();
  }

  /**
   * Draws a set of "puffs" that together look like a cloud shape.
   */
  function puffs(
    ctx: CanvasRenderingContext2D,
    t: number,
    cx: number,
    cy: number,
    rx: number,
    ry: number,
    rmin: number,
    rmax: number
  ) {
    function puff(
      ctx: CanvasRenderingContext2D,
      t: number,
      cx: number,
      cy: number,
      rx: number,
      ry: number,
      rmin: number,
      rmax: number
    ) {
      const c = Math.cos(t * TAU);
      const s = Math.sin(t * TAU);
      const rm = rmax - rmin;

      circle(
        ctx,
        cx - s * rx,
        cy + c * ry + rm * 0.5,
        rmin + (1 - c * 0.5) * rm
      );
    }

    for (let i = 0; i < 5; i++) {
      puff(ctx, t + i / 5, cx, cy, rx, ry, rmin, rmax);
    }
  }

  /**
   * Draws a two-tone cloud: an outer color + an inner color.
   */
  function cloud(
    ctx: CanvasRenderingContext2D,
    t: number,
    cx: number,
    cy: number,
    cw: number,
    s: number,
    outerColor: string,
    innerColor: string
  ) {
    // slow drift
    t /= 30000;

    const a = cw * 0.21;
    const b = cw * 0.12;
    const c = cw * 0.24;
    const d = cw * 0.28;

    // --- 1) Draw the outer cloud shape ---
    ctx.fillStyle = outerColor;
    puffs(ctx, t, cx, cy, a, b, c, d);

    // --- 2) Carve out a smaller region in the center to make room for another color ---
    ctx.save();
    ctx.globalCompositeOperation = "destination-out";
    puffs(ctx, t, cx, cy, a, b, c - s, d - s);
    ctx.restore();

    // --- 3) Fill that smaller region with the 'innerColor' ---
    ctx.fillStyle = innerColor;
    puffs(ctx, t, cx, cy, a, b, c - s, d - s);
  }

  /**
   * Draws raindrops falling from the cloud.
   */
  function rain(
    ctx: CanvasRenderingContext2D,
    t: number,
    cx: number,
    cy: number,
    cw: number,
    s: number,
    dropColor: string,
    count: number,
    speedMultiplier: number
  ) {
    // 2000ms per cycle, scaled by speed
    t /= (2000 / speedMultiplier);

    const xSpread = cw * 0.16;
    const arcStart = TAU * (11 / 12);
    const arcEnd = TAU * (7 / 12);

    for (let i = 0; i < count; i++) {
      const p = (t + i / 4) % 1;
      const x = cx + ((i - 1.5) / 1.5) * xSpread;
      const y = cy + p * p * cw;

      ctx.fillStyle = dropColor;
      ctx.beginPath();
      ctx.moveTo(x, y - s * 1.5);
      ctx.arc(x, y, s * 0.75, arcStart, arcEnd, false);
      ctx.fill();
    }
  }

  /**
   * Clears the canvas and draws one frame of the animation.
   */
  function drawFrame(ctx: CanvasRenderingContext2D, elapsed: number) {
    const w = ctx.canvas.width;
    const h = ctx.canvas.height;
    const s = Math.min(w, h);

    // Clear canvas
    ctx.clearRect(0, 0, w, h);

    // 1) Draw raindrops first
    rain(ctx, elapsed, w * 0.5, h * 0.37, s * 0.9, s * STROKE, rainColor, dropCount, speed);

    // 2) Draw the two-tone cloud
    cloud(ctx, elapsed, w * 0.5, h * 0.37, s * 0.9, s * STROKE, cloudColor, cloudInnerColor);
  }

  /**
   * Main animation loop using requestAnimationFrame.
   */
  const animate = (timestamp: number) => {
    if (startTimeRef.current === null) {
      startTimeRef.current = timestamp;
    }
    const elapsed = timestamp - startTimeRef.current;

    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        drawFrame(ctx, elapsed);
      }
    }
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current);
      }
    };
    // Re-run if any props change
  }, [cloudColor, cloudInnerColor, rainColor, size, dropCount, speed]);

  return (
    <canvas
      ref={canvasRef}
      className={className}
      width={size}
      height={size}
      style={{ width: size, height: size }}
    />
  );
};

export default AnimatedRain;
